import React, { FC, FocusEvent, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import z from 'zod';
import axios from 'axios';
import styled from 'styled-components';
import Text from 'components/Text';
import { Loader, LoadingPage, PageContainer, Seo, TextInput } from 'components';
import Header from 'components/Header';
import { mobile, tablet, useQuery } from 'styles/breakpoints';
import { AppState } from 'state/types';
import { useRouter } from 'apis/history';
import { config } from 'config';
import Button from 'components/PrimaryButton';
import { usePageView, useQuizData } from 'utils/hooks';
import { klaviyoLink } from 'utils/klavyio';
import RegisterCompleted from './components/RegisterCompleted';
import Tracking from 'utils/tracking';
import { normalizeStates } from 'utils/localization';
import { PasswordInput } from 'components/inputs/PasswordInput';

interface FormData {
  email: string;
  emailConfirm: string;
  password?: string;
  repeatedPassword?: string;
}

const defaultValues: FormData = {
  email: '',
  emailConfirm: '',
  password: undefined,
  repeatedPassword: undefined,
};

const validateSchema = z
  .object({
    email: z
      .string({ required_error: 'Please enter your email' })
      .email('Please enter a valid email address'),
    emailConfirm: z.string(),
    password: z
      .string({ required_error: 'Please enter your password' })
      .min(6, 'Password must be no shorter than 6 symbols'),
    repeatedPassword: z.string({
      required_error: 'Please confirm your password',
    }),
  })

  .refine(data => data.password === data.repeatedPassword, {
    message: 'Confirm password do not match with your password',
    path: ['repeatedPassword'],
  });

const ContentContainer = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  justify-content: center;
  flex-direction: column;
  max-width: 30.75rem;
  width: 100%;
  padding: 2.375rem 1rem;
  margin: 0 auto;
  @media ${tablet} {
    padding: 1.5rem 1rem;
  }
`;

const Title = styled(Text)`
  color: #000;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: 2rem;
  margin-bottom: 0.5rem;
  width: 100%;
`;

const Subtitle = styled(Text)`
  color: #4d4d4d;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25rem;
  width: 100%;
`;

const Form = styled.form`
  width: 100%;
  padding: 1.5rem 0 6rem;
  align-items: center;
  display: flex;
  flex-direction: column;

  @media ${mobile} {
  }
`;

const StyledButton = styled(Button)`
  width: 100%;
  max-width: 28.75rem;
  margin: 0 auto;
`;

const FormContentContainer = styled.div`
  width: 100%;
  padding-bottom: 3rem;

  @media ${mobile} {
    padding-bottom: 2rem;
  }
`;

const Register: FC = () => {
  const [loading, setLoading] = useState(false);
  const { isTablet } = useQuery();

  const [errors, setErrors] =
    useState<Partial<Record<keyof FormData, string>>>();
  const [formValues, setFormValues] = useState<FormData>(defaultValues);
  const [isRegisterComplete, setIsRegisterComplete] = useState<boolean>(false);

  const { quiz_answers, user, code, selected_plan, geolocation } = useSelector(
    (state: AppState) => state.user,
  );
  const upgradeSequences = useQuizData('upgradeSequences');

  const data = useQuizData('register');

  usePageView({
    country: geolocation?.iso_country?.toLowerCase() || 'no-country-fallback',
    state: normalizeStates(
      geolocation?.iso_country || '',
      geolocation?.iso_state || '',
    ),
    city: encodeURIComponent(
      geolocation?.city?.toLowerCase().replace(/[^a-z0-9]/g, '') || '',
    ),
    email: user?.email.trim() || 'no-email-fallback',
    gender: quiz_answers?.gender ? quiz_answers?.gender[0] : 'f',
    client_code: code,
  });

  const { goToUpgrade } = useRouter();

  const quiz = new URLSearchParams(location.search).get('qz') ?? 'main-wy';
  const caseParam = new URLSearchParams(location.search).get('case');
  const isFromSupportCase = caseParam === 'support';

  useEffect(() => {
    if (user) {
      formValues.email = user.email;
      formValues.emailConfirm = user.email;
      setFormValues(formValues);
    }
  }, [user]);

  const getTodayDateString = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    return `${year}${month}${day}`;
  };

  const isWeightInLbs = 'lb' in quiz_answers?.current_weight;

  const lbsToKg = lbs => Number((lbs * 0.453592).toFixed(4));

  const heightToCm = () => {
    const { ft, in: inches } = quiz_answers?.height;
    return Math.round(ft * 30.48 + inches * 2.54);
  };

  const activityLevels = {
    week_5_more: 5,
    week_3_4: 4,
    week_1_2: 3,
    once_a_week: 2,
    dont_exercise_regularly: 1,
  };

  const userRTDBProperties = {
    weightHistory: {
      [getTodayDateString()]: isWeightInLbs
        ? lbsToKg(quiz_answers?.current_weight?.lb)
        : quiz_answers?.current_weight?.kg,
    },
    userDetails: {
      isQuizComplete: true,
      code: code,
      startingWeight: isWeightInLbs
        ? lbsToKg(quiz_answers?.current_weight?.lb)
        : quiz_answers?.current_weight?.kg,
      goalWeight: isWeightInLbs
        ? lbsToKg(quiz_answers?.target_weight?.lb)
        : quiz_answers?.target_weight?.kg,
      gender: 'female',
      height: isWeightInLbs ? heightToCm() : quiz_answers?.height?.cm,
      activityLevel: activityLevels[quiz_answers?.exercise_during_week],
      age: quiz_answers?.age?.year,
      unitsOfMeasure: 'imperial',
    },
  };

  const handleRegister = async (data: FormData) => {
    setLoading(true);

    // await klaviyoLink(data.email);

    Tracking.trackCTAButton(location.pathname);

    const userCredentials = {
      email: data.email,
      password: data.password,
    };

    // const onboardingBooked = sessionStorage.getItem('onboardingBooked');

    const funnelData = {
      code: code ?? '',
      quizAnswers: quiz_answers ?? {},
      selectedPlan: selected_plan ?? {},
      quiz,
      onboardingBooked: false,
    };

    try {
      await axios.post(config.FIREBASE_REGISTER_URL, {
        userCredentials,
        userRTDBProperties,
      });

      Tracking.trackRegistration(funnelData.code);
      // if user came from support email - prevent going to upgrade sequence
      if (isFromSupportCase) {
        setIsRegisterComplete(true);
        setLoading(false);
        return;
      }
    } catch (error) {
      console.error(error);

      setLoading(false);
    }

    const selectedPlanKey = selected_plan?.key || null;
    const upgradeKey = selectedPlanKey
      ? Object.keys(upgradeSequences[selectedPlanKey])[0]
      : null;
    if (upgradeKey) {
      goToUpgrade(upgradeKey);
    } else {
      goToUpgrade('upgrade');
    }
  };

  const handleFocus = (e: FocusEvent<HTMLInputElement>) => {
    e.target.scrollIntoView();
  };

  const validateInputs = async (data: FormData) => {
    const validationErrors: Record<string, string> = {};
    try {
      validateSchema.parse(data);
    } catch (err: any) {
      if (err instanceof z.ZodError) {
        err.issues.forEach(e => {
          validationErrors[e.path[0]] = e.message;
        });
      }
    }
    setErrors(validationErrors);
    return validationErrors;
  };

  const validateInput = async (data: Partial<FormData>, field: string) => {
    const validationErrors: Record<string, string> = {};
    try {
      validateSchema.parse({
        ...formValues,
        [field]: data[field as keyof FormData],
      });
    } catch (err) {
      if (err instanceof z.ZodError) {
        err.issues.forEach(e => {
          if (e.path[0] !== field) return;
          validationErrors[e.path[0]] = e.message;
        });
      }
    }
    const isEmpty = Object.keys(validationErrors).length === 0;
    if (isEmpty) {
      return setErrors({ ...errors, [field]: null });
    }
    return setErrors({ ...errors, ...validationErrors });
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const validationErrors = await validateInputs(formValues);
    if (Object.keys(validationErrors).length === 0) {
      handleRegister(formValues);
    }
  };

  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormValues({
      ...formValues,
      [event.target.name]: event.target.value,
    });
    await validateInput(
      { [event.target.name]: event.target.value },
      event.target.name,
    );
  };

  if (!data) {
    return <LoadingPage />;
  }

  return (
    <>
      <Seo />
      <Header hasDivider={true} />
      <PageContainer>
        {isRegisterComplete ? (
          <RegisterCompleted />
        ) : (
          <ContentContainer>
            <Title>{data?.title}</Title>
            <Subtitle>{data?.subtitle}</Subtitle>

            <Form onSubmit={handleSubmit}>
              <FormContentContainer>
                <InputWrapper>
                  <TextInput
                    name="email"
                    defaultValue={user?.email ?? ''}
                    label={data?.emailLabel}
                    error={errors?.email}
                    disabled={true}
                    onChange={handleChange}
                    onFocus={handleFocus}
                    required
                  />
                </InputWrapper>
                <InputWrapper>
                  <TextInput
                    name="emailConfirm"
                    defaultValue={user?.email ?? ''}
                    label={data?.confirmEmailLabel}
                    error={errors?.emailConfirm}
                    disabled={true}
                    onChange={handleChange}
                    onFocus={handleFocus}
                    required
                  />
                </InputWrapper>
                <InputWrapper>
                  <PasswordInput
                    type="password"
                    name="password"
                    label={data?.passwordLabel}
                    error={errors?.password}
                    disabled={loading}
                    onChange={handleChange}
                    onFocus={handleFocus}
                    required
                  />
                </InputWrapper>
                <InputWrapper>
                  <PasswordInput
                    type="password"
                    name="repeatedPassword"
                    label={data?.confirmPasswordLabel}
                    error={errors?.repeatedPassword}
                    disabled={loading}
                    onChange={handleChange}
                    onFocus={handleFocus}
                    required
                  />
                </InputWrapper>
              </FormContentContainer>
              <Wrapper>
                <StyledButton
                  type="submit"
                  loading={loading}
                  disabled={loading}
                >
                  {data?.submitBtnTitle}
                </StyledButton>
              </Wrapper>
            </Form>
          </ContentContainer>
        )}
      </PageContainer>
    </>
  );
};

const InputWrapper = styled.div`
  margin-top: 0.6rem;
`;

export default Register;

const Wrapper = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 1.5rem 1rem;
  width: 100%;
  border-top: 1px solid #efefef;
  background: #fff;
  @media ${tablet} {
    padding: 1rem;
  }
`;
